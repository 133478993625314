<template>
  <el-row type="flex"  justify="center" align="middle">
      <div>
        <a target="_blank" href="https://beian.miit.gov.cn/">鄂ICP备18008354号-4</a>
        ©2022 Allen Zhang All rights reserved</div>
  </el-row>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
