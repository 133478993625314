<template>
  <div :class="route" class="bg" :style="bg_size">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'Main',
  computed: {},
  watch: {
    $route: function (to) {
      this.route = to.name.toLowerCase()
    },
    screenWidth: function () {
      this.windowChange();
    },
    screenHeight: function () {
      this.windowChange();
    },
  },
  data() {
    return {
      route: 'home',
      bg_size: "background-size:100% auto;",
      windowLock: 0,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    window.onresize = () => {
      return (() => {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
      })()
    }
    this.windowChange();
  },
  methods: {
    windowChange() {
      if (!this.windowLock) {
        this.windowLock = 1;
        setTimeout(() => {
          this.windowLock = 0;
          this.bg_size = this.screenWidth / this.screenHeight >= 1920 / 1080 ? "background-size:101% auto;" : "background-size:auto 101%";
        }, 10)
      }
    }
  }


}
</script>

