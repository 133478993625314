<template>
  <el-row type="flex">
    <el-col :span="5" :offset="1">
      <el-row type="flex" class="logo mm-logo-width" justify="start" align="middle">
        <el-col>
          <img src="../images/logo.png">
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="13" :offset="4">
      <el-row type="flex" class="nav-box mm-nav-width">
        <el-col :span="22">
          <el-row type="flex" class="nav" justify="end" align="middle" v-if="menu_show">
            <el-col :span="3">
              <router-link to="/">首页</router-link>
            </el-col>
            <el-col :span="3">
              <router-link to="/list">文章</router-link>
            </el-col>
            <!--            <el-col :span="3">-->
            <!--              <router-link to="/article">Article</router-link>-->
            <!--            </el-col>-->
            <el-col :span="3">
              <router-link to="/tool">工具</router-link>
            </el-col>
            <el-col :span="3">
              <router-link to="/about">关于</router-link>
            </el-col>
            <el-col :span="3">
              <router-link to="/plan">随笔</router-link>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="2">
          <el-row type="flex" class="nav-icon" justify="end" align="middle">
            <el-col>
              <div @click="menuChange" v-if="menu_show == 0"><img src="../images/menu.png"></div>
              <div @click="menuChange" v-if="menu_show == 1">
                <img src="../images/menu-hide.png">
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      menu_show: 1
    }
  },
  methods: {
    menuChange() {
      this.menu_show = this.menu_show ? 0 : 1;
    }
  }
}
</script>

