<template>
  <div class="layout">
    <div class="header">
      <Header/>
    </div>
    <div class="main">
      <Main/>
    </div>
    <div class="footer">
      <Footer/>
    </div>
  </div>
</template>

<style lang="scss">
@import "styles/init.css";
@import "styles/index.scss";
</style>

<script>
import Header from '@/components/Header.vue'
import Main from '@/components/Main.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header, Main, Footer
  }
}
</script>
