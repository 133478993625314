import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'                              // px2rem
import ElementUI from 'element-ui';                 // element-ui
import 'element-ui/lib/theme-chalk/index.css';      // element-ui-css

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
